import React, { useEffect } from 'react'
// import puppeteer from "puppeteer";
import data from '../../json/news.json'
import pattern from '../../assets/pattern4.png'
import styles from './Home.module.css'
const News = () => {
  // const puppeteer = require('puppeteer')
  const googleURL = `https://news.google.com/rss/search?q=pet`
  const timesURL = `https://timesofindia.indiatimes.com/topic/pet`

  useEffect(() => {
    scrapeNews(googleURL)
  }, [])


  const scrapeNews = async (url) => {
    //   const browser = await puppeteer.launch()
    //   const page = await browser.newPage()
    //   await page.goto(url)

    //   const [el] = await page.$x(`//*[@id="storyBody"]/div/div[2]/div/div[1]/div/div[1]/div/div[1]/a/div/div[1]/span`)
    //   const txt = await el.getProperties()
  }
  // const res = fetch(googleURL)
  // console.log('res', res);

  return (
    <>
      <div className='w-[100%] max-w-[1440px] mx-auto  mt-[60px] sm:mt-[90px] '>
        <div className='w-[100%] flex justify-center'>
          <p className='font-[500] text-[30px] sm:text-[48px] fredoka'>News & Blogs</p>
        </div>
        <div className='overflow-x-auto h-[600px] flex  mt-[20px] sm:mt-[30px] hideScroll px-[3%] items-center'
          onDrag={e => window.scrollY = (window.scrollY + '10px')}
        >
          {data?.news?.map(obj => {
            return (
              <>
                {/* hover:h-[450px] hover:min-w-[330px] */}
                <div className={`h-[400px] min-w-[300px] mr-[20px] sm:mr-[30px] rounded-[10px] bg-[#58003C] overflow-hidden shadow-2xl relative 
                text-white smooth select-none nunito`}
                  data-aos="slide-right"
                  data-aos-duration="1500"
                >
                  <img src={pattern} alt="" className='w-[100%] h-[45px] object-cover opacity-[40%]' />
                  <div className='px-[16px] py-[10px] '>
                    <p className='font-[300] text-[10px] sm:text-[px] sm:mt-[px]'>{obj?.source}</p>
                    <p className='font-[500] text-[18px] sm:text-[px] mt-[8px] sm:mt-[px] overflow-hidden text-ellipsis max-h-[80px]'>{obj?.heading}</p>
                    {/* <p className='font-[400] text-[14px] sm:text-[px] mt-[12px] sm:mt-[px] text-ellipsis'>{obj?.description}</p> */}
                  </div>
                  <div className='bg-white rounded-full text-[#78006e] w-[100px] flex items-center justify-center font-[500] mx-[16px] py-[10px] hover:cursor-pointer'
                    onClick={() => { window.open(obj?.link) }}
                  >Read More</div>
                  <div className=''>
                    <img src={obj?.img} alt="" className='w-[100%] object-cover absolute bottom-0 h-[160px] select-none' />
                  </div>

                </div>
              </>
            )
          })}

        </div>
      </div>
    </>
  )
}

export default News
