import React from 'react'
import logo from '../../assets/logo-colored.png'
import bg from '../../assets/footer/footer-bg-3.png'
import bg2 from '../../assets/footer/footer-bg-4.png'
import whatsapp from '../../assets/whatsapp.png'
import instagram from '../../assets/instagram.png'
import phone from '../../assets/call.png'


import './footer.css'
const Footer = () => {
  return (
    <>
      {/* bg-[#78006e] */}
      <div className='w-[100%] relative'>
        <div className=' flex w-[100%] '>
          <img src={bg2} alt="" className='' />
        </div>
        <div className='absolute bottom-0'>
          <img src={bg} alt="" className='w-[100%]' />
          <div className='w-[100%]  absolute bottom-0 flex items-center justify-between flex-col pt-[170px] sm:pt-[250px] pb-[30px] text-[#78006e] nunito'>
            <img src={logo} alt="" className='h-[105px] md:h-[105px] sm:mt-[30px] hidden sm:block mb-[3%]' />
            <div className='flex gap-[50px]'>
              <div className='flex items-center hover:cursor-pointer'
                onClick={() => window.open('https://wa.me/+919846131000')}
              >
                <img src={whatsapp} alt="" className='h-[20px]' />
                <p className='ml-[6px] text-[12px]'>Whatsapp</p>
              </div>
              <div className='flex items-center hover:cursor-pointer'
                onClick={() => window.open('https://instagram.com/mypethauler')}
              >
                <img src={instagram} alt="" className='h-[20px]' />
                <p className='ml-[6px] text-[12px]'>Instagram</p>
              </div>
              <div className='flex items-center hover:cursor-pointer'
                onClick={() => window.open('tel:+919846131000')}
              >
                <img src={phone} alt="" className='h-[20px]' />
                <p className='ml-[6px] text-[12px]'>Call</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
