import React from 'react'
import Intro from '../components/Home/Intro'
import News from '../components/Home/News'
import OurServices from '../components/Home/OurServices'
import Pets from '../components/Home/Pets'
import Navbar from '../components/navbar/Navbar'

import map from '../assets/map.png'
import Footer from '../components/Footer/Footer'

const Home = () => {
  return (
    <>
      <div className=''>
        <Navbar />
        <Intro />
        <OurServices />
        <Pets />
        <div className='w-[100%] flex justify-center mt-[30px] sm:mt-[50px]'
        >
          <img src={map} alt="" />
        </div>
        <News />
        <Footer />
      </div>
    </>
  )
}

export default Home
