import React from 'react'
import { useNavigate } from 'react-router-dom'

import logo from '../../assets/logo.svg'

const Navbar = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className='w-[100%] max-w-[1440px] px-[3%] mx-auto'>
        <div className='h-[100px] md:h-[120px] w-[100%] flex items-center justify-between relative'>
          <img src={logo} alt="" className='h-[50px] md:h-[105px] sm:mt-[30px] hover:cursor-pointer' onClick={()=>navigate('/')}/>
          {/* <div className='hidden sm:block'>
            <div className='font-[400] flex gap-[10px] md:gap-[30px] text-[#322133] md:text-[20px] '>
              <div className='hover:cursor-pointer'>Home</div>
              <div className='hover:cursor-pointer'>Services</div>
              <div className='hover:cursor-pointer'>Destinations</div>
              <div className='hover:cursor-pointer'>Contact Us</div>
              <div className='hover:cursor-pointer'>News</div>
            </div>
          </div> */}
          <div className='bg-white rounded-full w-[150px] h-[40px] flex items-center justify-center hover:cursor-pointer text-[500]'
            onClick={ ()=>navigate('/book')}
          >Get a quotation</div>
        </div>
      </div>

    </>
  )
}

export default Navbar
