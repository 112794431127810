import React, { useEffect } from 'react'

import data from '../../json/pets.json'
import styles from './Home.module.css'
import '../../App.css'
import { useNavigate } from 'react-router-dom'

const Pets = () => {
  const navigate = useNavigate()

  return (
    <>
      <div className='w-[100%] max-w-[1440px] mx-auto pt-[60px] sm:pt-[120px]'>
        <p className='font-[500] text-[30px] sm:text-[48px] text-center fredoka'>Pets we Move</p>
        <div className='overflow-x-auto flex hideScroll mt-[20px] sm:mt-[30px] h-[300px] px-[3%] items-center'>
          {data?.pets?.map((obj, idx) => {
            // console.log(obj);
            return (
              <>
                <div className={`w-[250px] hover:w-[270px] ${styles.card} mr-[20px] sm:mr-[30px] h-auto flex flex-col items-center ${styles.hidden} ${styles.show} ${styles.slideRight}`}
                  data-aos="fade-in"
                  data-aos-duration="1000"
                  onClick={()=>navigate('/book')}
                >
                  <div className='h-[190px] w-[190px] hover:w-[200px] hover:h-[200px] rounded-[60px] flex items-center justify-center bg-[#58003C] smooth'>
                    {obj?.img ?
                      <img src={obj?.img} alt="" className='w-[70px] hover:w-[100px] smooth'
                        onMouseDown={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}
                      />
                      : <h3 className='text-white'>{obj?.name}</h3>
                    }
                  </div>
                  <p className='font-[600] text-[16px] leading-[19px] mt-[20px] nunito'>{obj?.name}</p>
                </div>
              </>
            )
          })}

        </div>

      </div>
    </>
  )
}

export default Pets
