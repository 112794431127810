import React, { useState } from 'react'
import send from '../../src/assets/send.png'

const Banner = () => {
  const [message, setMessage] = useState()
  return (
    <>
      <div className='w-[100%] h-[250px] bg-white mt-[30px] sm:mt-[60px] md:mt-[90px] fredoka relative'
      >
        {/* <img src={pattern} alt="" className=' w-[100%] h-[100%] object-cover opacity-[10%]' /> */}
        <div className='absolute top-0 w-[100%] h-[100%] flex flex-col items-center justify-center px-[4%]'>
          <p className='max-w-[800px] text-center text-[18px] md:text-[28px] text-[#322133]'>
            We would be happy to assist you with any questions or concerns you may have. Please don't hesitate to reach out to us at any time.
          </p>
          <div className='flex items-center justify-between px-[20px] nunito bg-[#322133] text-white hover:text-[#FFD700] rounded-full h-[50px] max-w-[450px] w-[80%] hover:cursor-pointer mt-[10px] '
          // onClick={() => window.open('https://wa.me/+919846131000')}
          >
            <input type="text" className='bg-inherit outline-none w-[93%]' placeholder='Talk to Us!'
              onChange={e => setMessage(e.target.value)}
            />
            <img src={send} alt="" className='h-[25px]'
              onClick={() => { window.open(`https://api.whatsapp.com/send?phone=+919846131000&text=${message}`) }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Banner
