import React from 'react'
const Intro = () => {
  return (
    <>
      <div className='w-[100%] max-w-[1440px] px-[3%] mx-auto '>
        <div className='flex sm:justify-center sm:h-[500px]'>
          <div className='md:w-[50%] flex flex-col pt-[30px] sm:pt-[90px] md:pt-[120px]'>
            {/* <img src={dog} alt="" /> */}
            <h1 className='text-[38px] sm:text-[52px] font-[500] leading-[48px] sm:leading-[63px] text-center sm:text-left mt-[20px] text-white fredoka'
              data-aos="fade-right"
              data-aos-duration="1000"
            >Your Pet Deserves <br /><span className='text-white text-[52px] sm:text-[60px]'>The best !</span></h1>
            <h3 className='mt-[20px] text-[18px] sm:text-[24px] text-center sm:text-left text-white nunito'>"Welcome to PetHauler! We understand that moving can be a stressful and overwhelming process, especially when it comes to relocating your beloved pets. That's why we're here to help"</h3>
          </div>
          <div className='md:w-[50%]'>
          </div>
        </div>
      </div>
    </>
  )
}

export default Intro
