import React, { useState, useEffect } from 'react'
import emailjs from 'emailjs-com';
import Footer from '../components/Footer/Footer'
import Navbar from '../components/navbar/Navbar'
import { useNavigate } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import pet from '../json/pets.json'
const Book = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let date = new Date()
  let month = (date.getMonth() + 1) / 10 < 1 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
  let day = (date.getDate()) / 10 < 1 ? '0' + (date.getDate()) : (date.getDate())
  let today = date.getFullYear() + "-" + month + "-" + day

  const [fromCity, setFromCity] = useState([])
  const [toState, setToState] = useState([])
  const [toCity, setToCity] = useState([])
  const navigate = useNavigate()
  const [submitted, setSubmitted] = useState(false)
  const [data, setData] = useState({
    service: '',
    travel: '',
    type: '',
    breed: '',
    dob: '',
    firstName: '',
    lastName: '',
    length: '',
    height: '',
    width: '',
    weight: '',
    owner: '',
    email: '',
    mobile1: '',
    mobile2: '',
    originCountry: '',
    originState: '',
    originCity: '',
    originPin: '',
    destinationCountry: '',
    destinationState: '',
    destinationCity: '',
    destinationPin: '',
    comments: '',
  })

  const { service, travel, type, breed, dob, firstName, lastName, length, height, width, weight, owner, email, mobile1, mobile2, originCountry, originState, originCity, originPin, destinationCountry, destinationState, destinationCity, destinationPin, comments } = data

  const handleChange = (e, name, value) => {
    if (e.type == 'click') {
      setData({ ...data, [name]: value })
    } else {
      setData({ ...data, [e.target.name]: e.target.value })
    }

    if (name == 'originState') {
      setFromCity(City.getCitiesOfState('IN', State.getStatesOfCountry('IN').find(o => o.name === value).isoCode))
    } else if (name == 'destinationCountry') {
      setToState(State.getStatesOfCountry(Country.getAllCountries().find(o => o.name === value).isoCode))
    } else if (name == 'destinationState' && service !== 'Domestic') {
      let country = Country.getAllCountries().find(o => o.name === destinationCountry).isoCode
      setToCity(City.getCitiesOfState(country, State.getStatesOfCountry(country).find(o => o.name === value).isoCode))
    } else if (name == 'destinationState' && service == 'Domestic') {
      console.log('Here');
      setToCity(City.getCitiesOfState('IN', State.getStatesOfCountry('IN').find(o => o.name === value).isoCode))
    }

    if (e.target.name == 'originState') {
      // console.log(State.getStatesOfCountry('IN').find(o => console.log(o.name.toLowerCase(), )  ));
      setFromCity(City.getCitiesOfState('IN', State.getStatesOfCountry('IN').find(o => o.name === e.target.value).isoCode))
    } else if (e.target.name == 'destinationCountry') {
      setToState(State.getStatesOfCountry(Country.getAllCountries().find(o => o.name === e.target.value).isoCode))
    } else if (e.target.name == 'destinationState' && service !== 'Domestic') {
      let country = Country.getAllCountries().find(o => o.name === destinationCountry).isoCode
      setToCity(City.getCitiesOfState(country, State.getStatesOfCountry(country).find(o => o.name === e.target.value).isoCode))
    } else if (e.target.name == 'destinationState' && service == 'Domestic') {
      console.log('Here');
      setToCity(City.getCitiesOfState('IN', State.getStatesOfCountry('IN').find(o => o.name === e.target.value).isoCode))
    }

  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(data);
    window.scrollTo(0, 0)
    setSubmitted(true)
    emailjs.sendForm('service_xzmsx1i', 'template_zesuygt', e.target, 'UbuzOBYnjL4nepS6B')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset()
  }

  return (
    <>
      <Navbar />
      <div className='w-[100%] max-w-[1440px] px-[3%] mx-auto  py-[40px] nunito'>
        <div className='bg-white rounded-[10px] w-[100%] max-w-[900px] px-[3%] py-[4%] mx-auto shadow-2xl '
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {!submitted &&
            <div>
              <form onSubmit={handleSubmit} className='' autoComplete='off'>
                {/* Details */}
                <h3 className='text-center fredoka mb-[30px]'>We need a few details</h3>
                <div className=' flex flex-wrap mt-[20px] '>
                  <div className='form-group mr-[10px] mt-[10px] flex items-center justify-between w-[100%] md:w-[48%]'>
                    <label htmlFor="service" className='mr-[10px]'>Service<span className='text-[red]'>*</span></label>
                    <select id="service" name="service"
                      className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] max-w-[300px] px-[3%] py-[2%]'
                      onChange={handleChange}
                      required
                      value={service}
                    >
                      <option value="">Choose Service</option>
                      <option value="International">International</option>
                      <option value="Domestic">Domestic</option>
                    </select>
                  </div>
                  <div className='form-group mr-[10px] mt-[10px] flex justify-between items-center w-[100%] md:w-[48%]'>
                    <label htmlFor='name' className=''>Travel Date</label>
                    <input
                      type="date"
                      min={today}
                      className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                      name='travel'
                      value={travel}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='form-group mr-[10px] mt-[10px] flex justify-between items-center w-[100%] md:w-[48%]'>
                    <label htmlFor='name' className=''>Pet Type<span className='text-[red]'>*</span></label>
                    <div className='flex flex-col relative w-[70%] md:w-[75%]'>
                      <input
                        type="text"
                        className='border-black border-[1px] rounded-md outline-none  px-[3%] py-[2%]'
                        name='type'
                        value={type}
                        id='type'
                        onChange={handleChange}
                        placeholder='Eg: Dog'
                        required
                      />
                      {/* <div className='dropdown absolute top-[35px] z-[2] w-[100%]'>
                        {pet?.pets?.filter((item) => {
                          const search = type.toLowerCase()
                          const pet = item.name.toLowerCase()
                          return search && pet.startsWith(search) && pet !== search
                        })
                          .map((obj, idx) => {
                            return (
                              <div className='dropdown-row' onClick={(e) => handleChange(e, 'type', obj.name)} key={idx} value={obj.name}>{obj.name}</div>
                            )
                          })
                        }
                      </div> */}
                    </div>
                    {/* <option value="">Choose Pet</option>
                      {pet?.pets?.map((obj, idx) => {
                        return (
                          <option key={idx} value={obj.name}>{obj.name}</option>
                        )
                      })}
                    </select> */}
                  </div>
                  <div className='form-group mr-[10px] mt-[10px] flex justify-between items-center w-[100%] md:w-[48%]'>
                    <label htmlFor='name' className=''>Pet Breed<span className='text-[red]'>*</span></label>
                    <div className='flex flex-col relative w-[70%] md:w-[75%]'>
                      <input
                        type="text"
                        className='border-black border-[1px] rounded-md outline-none px-[3%] py-[2%]'
                        name='breed'
                        value={breed}
                        onChange={handleChange}
                        placeholder='Eg: Husky'
                        required
                      />
                      <div className='dropdown absolute top-[35px] z-[2] w-[100%]'>
                        {pet?.[type]?.filter((item) => {
                          const search = breed.toLowerCase()
                          const petBreed = item.name.toLowerCase()
                          return search && petBreed.includes(search) && petBreed !== search
                        }).map((obj, idx) => {
                          return (
                            <div className='dropdown-row' onClick={(e) => handleChange(e, 'breed', obj.name)} key={idx} value={obj.name}>{obj.name}</div>
                          )
                        })
                        }
                      </div>
                    </div>
                    {/* <select
                      className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                      name='breed'
                      value={breed}
                      onChange={handleChange}
                    >
                      <option value="">Choose Breed</option>
                      {pet?.[type]?.map((obj, idx) => {
                        return (
                          <option key={idx} value={obj.name}>{obj.name}</option>
                        )
                      })}
                      <option value="Other">Other</option>
                    </select> */}


                  </div>
                  <div className='form-group mr-[10px] mt-[10px] flex justify-between items-center w-[100%] md:w-[48%]'>
                    <label htmlFor='name' className=''>Pet's Name<span className='text-[red]'>*</span></label>
                    <input
                      type="text"
                      className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                      name='firstName'
                      value={firstName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className='form-group mr-[10px] mt-[10px] flex justify-between items-center w-[100%] md:w-[48%]'>
                    <label htmlFor='' className=''>Last Name</label>
                    <input
                      type="text"
                      className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                      name='lastName'
                      value={lastName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='form-group mr-[10px] mt-[10px] flex justify-between items-center w-[100%] md:w-[48%]'>
                    <label htmlFor='name' className=''>DOB</label>
                    <input
                      type="date"
                      className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                      name='dob'
                      value={dob}
                      max={today}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='form-group mr-[10px] mt-[10px] flex justify-between items-center w-[100%] md:w-[48%]'>
                    <label htmlFor='name' className=''>Length (cm)</label>
                    <input
                      type="number"
                      className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                      name='length'
                      value={length}
                      onChange={handleChange}
                    // required
                    />
                  </div>
                  <div className='form-group mr-[10px] mt-[10px] flex justify-between items-center w-[100%] md:w-[48%]'>
                    <label htmlFor='name' className=''>Height (cm)</label>
                    <input
                      type="number"
                      className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                      name='height'
                      value={height}
                      onChange={handleChange}
                    // required
                    />
                  </div>
                  <div className='form-group mr-[10px] mt-[10px] flex justify-between items-center w-[100%] md:w-[48%]'>
                    <label htmlFor='name' className=''>Width (cm)</label>
                    <input
                      type="number"
                      className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                      name='width'
                      value={width}
                      onChange={handleChange}
                    // required
                    />
                  </div>
                  <div className='form-group mr-[10px] mt-[10px] flex justify-between items-center w-[100%] md:w-[48%]'>
                    <label htmlFor='name' className=''>Weight (gm)</label>
                    <input
                      type="number"
                      className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                      name='weight'
                      value={weight}
                      onChange={handleChange}
                    // required
                    />
                  </div>
                </div>

                {/* Owner Details */}
                <h3 className='fredoka my-[30px] text-center'>Owner Details</h3>
                <div className='flex flex-wrap mt-[20px] '>
                  <div className='form-group mr-[10px] mt-[10px] flex justify-between items-center w-[100%] md:w-[48%]'>
                    <label htmlFor='name' className=''>Name<span className='text-[red]'>*</span></label>
                    <input
                      type="text"
                      className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                      name='owner'
                      value={owner}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className='form-group mr-[10px] mt-[10px] flex justify-between items-center w-[100%] md:w-[48%]'>
                    <label htmlFor='name' className=''>Email<span className='text-[red]'>*</span></label>
                    <input
                      type="text"
                      className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                      name='email'
                      value={email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className='form-group mr-[10px] mt-[10px] flex justify-between items-center w-[100%] md:w-[48%]'>
                    <label htmlFor='name' className=''>Mobile No.<span className='text-[red]'>*</span></label>
                    <input
                      type="text"
                      className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                      name='mobile1'
                      value={mobile1}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className='form-group mr-[10px] mt-[10px] flex justify-between items-center w-[100%] md:w-[48%]'>
                    <label htmlFor='name' className=''>Alternate No.</label>
                    <input
                      type="text"
                      className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                      name='mobile2'
                      value={mobile2}
                      placeholder='Optional'
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* Transit Details */}
                <h3 className='fredoka my-[30px] text-center'>Transit Details</h3>
                <div className='grid grid-cols-1 sm:grid-cols-2'>
                  {/* From */}
                  <div className=''>
                    <span className='mx-auto fredoka'>From</span>
                    <div className=' flex flex-wrap mt-[10px] pr-[10px]'>
                      {service !== 'Domestic' &&
                        <div className='form-group mt-[10px] flex justify-between items-center w-[100%] '>
                          <label htmlFor='name' className=''>Country<span className='text-[red]'>*</span></label>
                          <select
                            className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                            id='originCountry'
                            name='originCountry'
                            value={originCountry}
                            onChange={handleChange}
                            required
                          >
                            <option value="India">India</option>
                          </select>
                        </div>
                      }
                      <div className='form-group mt-[10px] flex justify-between items-center w-[100%] '>
                        <label htmlFor='name' className=''>State<span className='text-[red]'>*</span></label>
                        <div className='flex flex-col relative w-[70%] md:w-[75%]'>
                          <input
                            type="text"
                            className='border-black border-[1px] rounded-md outline-none  px-[3%] py-[2%]'
                            name='originState'
                            value={originState}
                            onChange={handleChange}
                            placeholder='Eg: Kerala'
                            required
                          />
                          <div className='dropdown absolute top-[35px] z-[2] w-[100%]'>
                            {State.getStatesOfCountry('IN').filter((item) => {
                              const search = originState.toLowerCase()
                              const ogState = item.name.toLowerCase()
                              return search && ogState.includes(search) && ogState !== search
                            })
                              .map((obj, idx) => {
                                return (
                                  <div className='dropdown-row' onClick={(e) => handleChange(e, 'originState', obj.name)} key={idx} value={obj.name}>{obj.name}</div>
                                )
                              })
                            }
                          </div>
                        </div>
                        {/* <select
                          type="text"
                          className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                          name='originState'
                          value={originState}
                          onChange={handleChange}
                          required
                        >
                          <option value='' >Choose State</option>
                          {State.getStatesOfCountry('IN').map((obj, idx) => {
                            return (
                              <option key={idx} value={obj.name} >{obj.name}</option>
                            )
                          })}
                        </select> */}
                      </div>
                      <div className='form-group mt-[10px] flex justify-between items-center w-[100%] '>
                        <label htmlFor='name' className=''>City<span className='text-[red]'>*</span></label>
                        <div className='flex flex-col relative w-[70%] md:w-[75%]'>
                          <input
                            type="text"
                            className='border-black border-[1px] rounded-md outline-none  px-[3%] py-[2%]'
                            name='originCity'
                            value={originCity}
                            onChange={handleChange}
                            placeholder='Eg: Ernakulam'
                            required
                          />
                          <div className='dropdown absolute top-[35px] z-[2] w-[100%]'>
                            {fromCity?.filter((item) => {
                              const search = originCity.toLowerCase()
                              const ogCity = item.name.toLowerCase()
                              return search && ogCity.includes(search) && ogCity !== search
                            }).map((obj, idx) => {
                              return (
                                <div className='dropdown-row' onClick={(e) => handleChange(e, 'originCity', obj.name)} key={idx} value={obj.name}>{obj.name}</div>
                              )
                            })
                            }
                          </div>
                        </div>
                        {/* <select
                          type="text"
                          className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                          name='originCity'
                          value={originCity}
                          onChange={handleChange}
                        // required
                        >
                          <option value='' >Choose City</option>
                          {fromCity?.map((obj, idx) => {
                            return (
                              <option key={idx} value={obj.name} >{obj.name}</option>
                            )
                          })}
                        </select> */}
                      </div>
                      <div className='form-group mt-[10px] flex justify-between items-center w-[100%] '>
                        <label htmlFor='name' className=''>Pin<span className='text-[red]'>*</span></label>
                        <input
                          type="number"
                          className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                          name='originPin'
                          value={originPin}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  {/* To */}
                  <div className='mt-[30px] sm:mt-0'>
                    <span className='mx-auto fredoka'>To</span>
                    <div className='flex flex-wrap mt-[10px] pr-[10px]'>
                      {service !== 'Domestic' &&
                        <>
                          <div className='form-group mt-[10px] flex justify-between items-center w-[100%] '>
                            <label htmlFor='name' className=''>Country<span className='text-[red]'>*</span></label>
                            <div className='flex flex-col relative w-[70%] md:w-[75%]'>
                              <input
                                type="text"
                                className='border-black border-[1px] rounded-md outline-none  px-[3%] py-[2%]'
                                name='destinationCountry'
                                id='destinationCountry'
                                value={destinationCountry}
                                onChange={handleChange}
                                placeholder='Eg: United Arab Emirates'
                                required
                              />
                              <div className='dropdown absolute top-[35px] z-[2] w-[100%]'>
                                {Country.getAllCountries().filter((item) => {
                                  const search = destinationCountry.toLowerCase()
                                  const desCountry = item.name.toLowerCase()
                                  return search && desCountry.includes(search) && desCountry !== search
                                })
                                  .map((obj, idx) => {
                                    return (
                                      <div className='dropdown-row' onClick={(e) => handleChange(e, 'destinationCountry', obj.name)} key={idx} value={obj.name}>{obj.name}</div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                            {/* <select
                              type="text"
                              className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                              name='destinationCountry'
                              id='destinationCountry'
                              value={destinationCountry}
                              onChange={handleChange}
                              required
                            >
                              <option value='' >Choose Country</option>
                              {Country.getAllCountries().map((obj, idx) => {
                                return (
                                  <option key={idx} value={obj.name} >{obj.name}</option>
                                )
                              })}
                            </select> */}
                          </div>
                          <div className='form-group mt-[10px] flex justify-between items-center w-[100%] '>
                            <label htmlFor='name' className=''>State<span className='text-[red]'>*</span></label>
                            <div className='flex flex-col relative w-[70%] md:w-[75%]'>
                              <input
                                type="text"
                                className='border-black border-[1px] rounded-md outline-none  px-[3%] py-[2%]'
                                name='destinationState'
                                value={destinationState}
                                onChange={handleChange}
                                placeholder='Eg: Dubai'
                                required
                              />
                              <div className='dropdown absolute top-[35px] z-[2] w-[100%]'>
                                {toState?.filter((item) => {
                                  const search = destinationState.toLowerCase()
                                  const desState = item.name.toLowerCase()
                                  return search && desState.includes(search) && desState !== search
                                })
                                  .map((obj, idx) => {
                                    return (
                                      <div className='dropdown-row' onClick={(e) => handleChange(e, 'destinationState', obj.name)} key={idx} value={obj.name}>{obj.name}</div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                            {/* <select
                              type="text"
                              className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                              name='destinationState'
                              value={destinationState}
                              onChange={handleChange}
                              required
                            >
                              <option value='' >Choose State</option>
                              {toState?.map((obj, idx) => {
                                return (
                                  <option key={idx} value={obj.name} >{obj.name}</option>
                                )
                              })}
                            </select> */}
                          </div>
                          <div className='form-group mt-[10px] flex justify-between items-center w-[100%] '>
                            <label htmlFor='name' className=''>City<span className='text-[red]'>*</span></label>
                            <div className='flex flex-col relative w-[70%] md:w-[75%]'>
                              <input
                                type="text"
                                className='border-black border-[1px] rounded-md outline-none  px-[3%] py-[2%]'
                                name='destinationCity'
                                value={destinationCity}
                                onChange={handleChange}
                                placeholder='Eg: Deira'
                                required
                              />
                              <div className='dropdown absolute top-[35px] z-[2] w-[100%]'>
                                {toCity?.filter((item) => {
                                  const search = destinationCity.toLowerCase()
                                  const desCity = item.name.toLowerCase()
                                  return search && desCity.includes(search) && desCity !== search
                                }).map((obj, idx) => {
                                  return (
                                    <div className='dropdown-row' onClick={(e) => handleChange(e, 'destinationCity', obj.name)} key={idx} value={obj.name}>{obj.name}</div>
                                  )
                                })
                                }
                              </div>
                            </div>
                            {/* <select
                              type="text"
                              className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                              name='destinationCity'
                              value={destinationCity}
                              onChange={handleChange}
                              required
                            >
                              <option value='' >Choose City</option>
                              {toCity?.map((obj, idx) => {
                                return (
                                  <option key={idx} value={obj.name} >{obj.name}</option>
                                )
                              })}
                            </select> */}
                          </div>
                        </>
                      }
                      {service == 'Domestic' &&
                        <>
                          <div className='form-group mt-[10px] flex justify-between items-center w-[100%] '>
                            <label htmlFor='name' className=''>State<span className='text-[red]'>*</span></label>
                            <div className='flex flex-col relative w-[70%] md:w-[75%]'>
                              <input
                                type="text"
                                className='border-black border-[1px] rounded-md outline-none  px-[3%] py-[2%]'
                                name='destinationState'
                                value={destinationState}
                                onChange={handleChange}
                                placeholder='Eg: Kerala'
                                required
                              />
                              <div className='dropdown absolute top-[35px] z-[2] w-[100%]'>
                                {State.getStatesOfCountry('IN').filter((item) => {
                                  const search = destinationState.toLowerCase()
                                  const desState = item.name.toLowerCase()
                                  return search && desState.includes(search) && desState !== search
                                })
                                  .map((obj, idx) => {
                                    return (
                                      <div className='dropdown-row' onClick={(e) => handleChange(e, 'destinationState', obj.name)} key={idx} value={obj.name}>{obj.name}</div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                            {/* <select
                              type="text"
                              className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                              name='destinationState'
                              value={destinationState}
                              onChange={handleChange}
                              required
                            >
                              <option value='' >Choose State</option>
                              {State.getStatesOfCountry('IN').map((obj, idx) => {
                                return (
                                  <option key={idx} value={obj.name} >{obj.name}</option>
                                )
                              })}
                            </select> */}
                          </div>
                          <div className='form-group mt-[10px] flex justify-between items-center w-[100%] '>
                            <label htmlFor='name' className=''>City<span className='text-[red]'>*</span></label>
                            <div className='flex flex-col relative w-[70%] md:w-[75%]'>
                              <input
                                type="text"
                                className='border-black border-[1px] rounded-md outline-none  px-[3%] py-[2%]'
                                name='destinationCity'
                                value={destinationCity}
                                onChange={handleChange}
                                placeholder='Eg: Ernakulam'
                                required
                              />
                              <div className='dropdown absolute top-[35px] z-[2] w-[100%]'>
                                {toCity?.filter((item) => {
                                  const search = destinationCity.toLowerCase()
                                  const desCity = item.name.toLowerCase()
                                  return search && desCity.includes(search) && desCity !== search
                                }).map((obj, idx) => {
                                  return (
                                    <div className='dropdown-row' onClick={(e) => handleChange(e, 'destinationCity', obj.name)} key={idx} value={obj.name}>{obj.name}</div>
                                  )
                                })
                                }
                              </div>
                            </div>
                            {/* <select
                              type="text"
                              className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                              name='destinationCity'
                              value={destinationCity}
                              onChange={handleChange}
                            // required
                            >
                              <option value='' >Choose City</option>
                              {toCity?.map((obj, idx) => {
                                return (
                                  <option key={idx} value={obj.name} >{obj.name}</option>
                                )
                              })}
                            </select> */}
                          </div>
                        </>
                      }

                      <div className='form-group mt-[10px] flex justify-between items-center w-[100%] '>
                        <label htmlFor='name' className=''>Pin<span className='text-[red]'>*</span></label>
                        <input
                          type="number"
                          className='border-black border-[1px] rounded-md outline-none w-[70%] md:w-[75%] px-[3%] py-[2%]'
                          name='destinationPin'
                          value={destinationPin}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form-group mt-[10px] flex justify-between  w-[99%]'>
                  <label htmlFor='name' className=''>Comments</label>
                  <textarea
                    type="text"
                    className='border-black border-[1px] rounded-md outline-none w-[70%] h-[120px] md:w-[88%] px-[3%] py-[2%]'
                    name='comments'
                    value={comments}
                    rows='8'
                    placeholder='Optional'
                    onChange={handleChange}
                  />
                </div>
                <div className='w-[100%] flex justify-end mt-[30px]'>
                  <button type='submit' className='bg-[#58003C] text-white px-[30px] py-[10px] rounded-full outline-none'>Submit</button>
                </div>

              </form>
            </div>
          }
          {submitted &&
            <div className='flex flex-col items-center justify-center'>
              <h3 className='text-center w-[100%] max-w-[600px]'>Your enquiry has been submitted, PetHauler will get in touch with you soon</h3>
              <button className='mt-[30px] hover:cursor-pointer bg-blue-600 text-white rounded-md h-[40px] w-[150px]'
                onClick={() => navigate('/')}
              >Go to Home</button>
            </div>
          }
        </div>
      </div >
      <Footer />
    </>
  )
}

export default Book
