import './App.css';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import logo from './assets/temp/petlogo.png'
import loader from './assets/loading-paw.gif'
import Home from './pages/Home';

import up from './assets/move-up.svg'
import { useState } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Book from './pages/Book';
// ..
AOS.init();

function App() {
  const [showLoader, setShowLoader] = useState(true)
  useEffect(() => {
    const parallax = document.getElementById('parallax')
    parallax.style.backgroundPositionY = window.pageYOffset;
  }, [])

  setTimeout(() => {
    setShowLoader(false)
  }, 1500);

  const [showTopButton, setShowTopButton] = useState(false)
  window.addEventListener('scroll', () => {
    const parallax = document.getElementById('parallax')
    let offset = window.pageYOffset;
    // console.log(parallax);
    parallax.style.backgroundPositionY = "-" + offset * 0.5 + 'px'
    offset > 100 ? setShowTopButton(true) : setShowTopButton(false)
  })

  return (
    <>
      <div className='text-[#322133] bg nunito' id='parallax'>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/book" element={<Book />} />
          </Routes>
        </BrowserRouter>
      </div>
      {/* Page Loading animation */}
      {showLoader &&
        <div className='h-[100vh] w-[100%] fixed top-0 bg-white loader flex items-center justify-center smooth'>
          <img src={loader} alt="" className='h-[48px] md:h-[64px]' />
        </div>
      }
      {showTopButton &&
        <img src={up} alt="" className='fixed bottom-[60px] right-4'
          onClick={() => window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })}
        />
      }
    </>
  );
}

export default App;
